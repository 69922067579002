import { Divider } from "@mui/material";
import { format, parseISO } from "date-fns";

export const Comment = ({ comment }) => {

    const date = format(parseISO(comment.created_at), 'MMM Lo, yyyy K:mmaaa');

    return (
        <div style={{
            padding: "10px",
            borderBottom: "1px solid #eeeeee",
        }}>
            <div>{comment.text}</div>
            <div style={{ fontSize: "0.7em" }}>{`@${comment.created_by.name} - ${date}`}</div>
        </div>
    );
}