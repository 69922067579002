import { Fab, TextField } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

export const ItemPropertyField = ({ property, index, isAdd = false, onAdd, onRemove }) => {
    return (
        <div style={{ display: "flex", gap: "10px" }}>
            {isAdd ? (
                <Fab onClick={onAdd}><AddIcon /></Fab>
            ) : (
                <>
                    <Fab onClick={onRemove}><RemoveIcon /></Fab>
                    <TextField variant="filled" label="Name" style={{ flex: "1" }} id={`prop-name-${index}`} />
                    <TextField variant="filled" label="Value" style={{ flex: "3" }} id={`prop-value-${index}`} />
                </>
            )}
        </div>
    );
}