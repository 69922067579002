import { useEffect, useState } from "react";
import { Comment } from "./Comment";
import { MyListApi } from "../utils/restApi";

export const ItemComments = ({ itemId, initiateLoad }) => {

    const [comments, setComments] = useState([]);

    useEffect(() => {
        MyListApi.getComments('item', itemId)
            .then(data => {
                setComments(data.items);
            });
    }, []);

    return (
        <div>
            {comments.map(c => <Comment key={c.id} comment={c} />)}
        </div>
    );
}