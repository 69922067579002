import { Chip, Menu, MenuItem } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from "react";
import { MyListApi } from "../utils/restApi";
import { useDispatch, useSelector } from "react-redux";
import { getTags } from "../slices/tagsSlice";

export const TagChooser = ({ onSelect }) => {

    const dispatch = useDispatch();
    const [tag, setTag] = useState(null);
    const [anchor, setAnchor] = useState(null);
    const [open, setOpen] = useState(false);
    const tags = useSelector(state => state.tags);

    useEffect(() => {
        dispatch(getTags());
    }, []);

    const handleClick = e => {
        setAnchor(e.target);
        setOpen(!open);
    }

    const handleChoice = choice => {
        setOpen(false);
        setTag(choice);
        if (onSelect) onSelect(choice);
    }

    return (
        <div>
            <Chip icon={<ChevronRightIcon />} label={tag ? tag.name : 'None'} clickable={true} onClick={handleClick} />
            <Menu
                open={open}
                id="tag-chooser"
                anchorEl={anchor}
            >
                {tags.data.map(t => {
                    return (
                        <MenuItem key={t.id} onClick={() => handleChoice(t)}>{t.name}</MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}