import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MyListApi } from "../utils/restApi";
import { setNotification } from "../slices/notificationSlice";

export const RequestFriendDialog = forwardRef((props, ref) => {

    const user = useSelector(state => state.login.user);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState();

    useImperativeHandle(ref, () => {
        return {
            openRequestFriend: () => {
                setOpen(true);
            }
        }
    });

    const handleSave = () => {
        MyListApi.requestFriend(name)
            .then(() => {

            })
            .catch(error => {
                dispatch(setNotification({
                    message: error.message,
                    type: 'error',
                    open: true,
                }));
            });
        setOpen(false);
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Request Friend</DialogTitle>
            <DialogContent>
                <TextField id="friend_name" label="Username" variant="filled" onChange={e => setName(e.target.value)}></TextField>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
});