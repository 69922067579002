import MenuBookIcon from '@mui/icons-material/MenuBook';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import TvIcon from '@mui/icons-material/Tv';
import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';

const TagIcons = {
    'books': <MenuBookIcon />,
    'events': <NewspaperIcon />,
    'shows': <TvIcon />,
    'movies': <LocalMoviesIcon />,
    'pictures': <PhotoCameraIcon />
}

export const GetTagIcon = name => {
    if (name in TagIcons) {
        return TagIcons[name];
    }
    return <PsychologyAltIcon />
}

export const TagIcon = ({ name }) => {
    return (GetTagIcon(name));
}