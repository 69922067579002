import {
  Button,
  FormControl,
  TextField,
  Card,
  Grid,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../slices/loginSlice';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';


export const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.login.user);
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  const handleLogin = e => {
    e.preventDefault();
    const { username, password } = e.target.elements;
    dispatch(login(username.value, password.value));
  };

  return (
    <Grid item xs={12}>
      <Card>
        <form onSubmit={handleLogin}>
          <Grid
            container
            item
            xs={12}
            spacing={4}
            sx={{
              padding: '1em',
            }}
          >
            <Grid item xs={12}>
              Login
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <TextField id="username" label="Username" variant="filled" fullWidth />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <TextField id="password" label="Password" variant="filled" type='password' fullWidth />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" type="submit" size="small">Login</Button>
              {/* <Button variant="contained" size="small" onClick={() => loginWithRedirect()}>Login 2</Button> */}
            </Grid>
            <Grid item xs={12}>
              <Link href="/sign-up">Sign-Up</Link>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Grid>
  );
}