import Header from './Header';
import Footer from './Footer';
import { Grid } from '@mui/material';
import { Notification } from './Notification';
import { SideMenu } from './SideMenu';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// export default function Layout({ children }) {
const Layout = ({ children }) => {

  const user = useSelector(state => state.login.user);
  const navigate = useNavigate();

  useEffect(() => {
    // if (!user) {
    //   navigate('/login');
    // }
  }, []);

  return (
    <Grid container sx={{
      backgroundColor: "#232931",
      color: "#EEEEEE",
    }}>
      <Header />
      <Grid container item xs={2} alignContent="flex-end" flexDirection="column">
        <SideMenu />
      </Grid>
      <Grid container item xs={8}
        sx={{
          margin: 'auto',
          // maxWidth: '800px',
          backgroundColor: '#fff0f0',
          borderLeft: '1px solid',
          borderRight: '1px solid',
          // paddingLeft: '1em',
          // paddingRight: '1em',
          // paddingTop: '1em',
          padding: '1em',
          height: "100%",
        }}
      >
        {children}
      </Grid>
      <Grid container item xs={2}>
      </Grid>
      <Footer />
      <Notification />
    </Grid>
  );
};

export default Layout;