import { createSlice } from "@reduxjs/toolkit";
import { MyListApi } from "../utils/restApi";

export const tagsSlice = createSlice({
    name: 'tags',
    initialState: {
        data: [],
        status: 'INITIAL',
    },
    reducers: {
        setTags: (state, action) => {
            state.data = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
    },
});

export const getTags = () => {
    return async (dispatch, getState) => {

        const { tags } = getState();

        if (!['INITIAL', 'LOADED'].includes(tags.status)) {
            return;
        }

        dispatch(setStatus('FETCHING'));

        MyListApi.getTags()
            .then(data => {
                dispatch(setTags(data.items));
                dispatch(setStatus('LOADED'));
            });
    }
}

export const { setTags, setStatus } = tagsSlice.actions;
export default tagsSlice.reducer;