import { Button, Chip, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PeopleIcon from '@mui/icons-material/People';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import CategoryIcon from '@mui/icons-material/Category';
import SettingsIcon from '@mui/icons-material/Settings';
import { Friends } from "./Friends";
import { FriendRequests } from "./FriendRequests";
import { Settings } from "./Settings";
import { useEffect } from "react";
import { Browse } from "./Browse";

const UserProfileItems = ({ userId }) => {
    return (
        <Browse userId={userId} />
    );
}

const UserProfileFriends = props => {
    return (
        <div>
            <Typography variant="h5">Friends</Typography>
            <Friends />
        </div>
    );
}

const UserProfileFollowers = props => {
    return (
        <div>
            followers
        </div>
    );
}

const UserProfileSettings = props => {
    return (
        <div>
            <Typography variant="h5">Settings</Typography>
            <Settings />
        </div>
    );
}

const ProfileChip = ({ name, label, section, icon, selected }) => {
    const navigate = useNavigate();

    return (
        <Chip icon={icon} label={label} size="small" clickable={true} color={selected ? 'primary' : 'default'} onClick={() => navigate(`/user/${name}/${section}`)} />
    );
}

export const UserProfile = () => {

    const user = useSelector(state => state.login.user);
    const { userId, section } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, []);

    const handleSection = toSection => {
        navigate(`/user/${userId}/${toSection}`);
    }

    const isCurUserProfile = user.id === userId;
    const isOtherUser = !(user.id === userId);

    return (
        <div style={{
            width: "100%",
        }}>
            <Paper sx={{
                marginBottom: "16px",
            }}>
                <div style={{
                    padding: "16px",
                }}>
                    {/* {`@${name}`} */}
                </div>
                <div style={{
                    padding: "16px",
                    display: "flex",
                    gap: "8px",
                }}>
                    <Chip icon={<CategoryIcon fontSize="small" />} label="Items" size="small" clickable={true} color={section === 'items' ? "primary" : "default"} onClick={() => handleSection('items')} />
                    {!isOtherUser ? <Chip icon={<PeopleIcon fontSize="small" />} label="Friends" size="small" clickable={true} color={section === 'friends' ? "primary" : "default"} onClick={() => handleSection('friends')} /> : null}
                    {!isOtherUser ? <Chip icon={<SentimentVerySatisfiedIcon fontSize="small" />} label="Followers" size="small" clickable={true} color={section === 'followers' ? "primary" : "default"} onClick={() => handleSection('followers')} /> : null}
                    {!isOtherUser ? <Chip icon={<SettingsIcon fontSize="small" />} label="Settings" size="small" clickable={true} color={section === 'settings' ? 'primary' : 'default'} onClick={() => handleSection('settings')} /> : null}
                </div>
            </Paper>
            <Paper>
                <div style={{
                    padding: "16px",
                }}>
                    {section === 'items' ? <UserProfileItems userId={userId} /> :
                     section === 'friends' ? <UserProfileFriends /> :
                     section === 'followers' ? <UserProfileFollowers /> :
                     section === 'settings' ? <UserProfileSettings />
                    : null}
                </div>
            </Paper>
        </div>
    );
}