
import { Grid } from '@mui/material';
// import {makeStyles} from '@mui/styles';

// const useStyles = makeStyles({
//   footer: {
//     position: "absolute",
//     bottom: 0,
//     width: "100%",
//     height: "2.5rem",
//   },
// });

const Footer = () => {
  // const classes = useStyles();
  return (
    <Grid item xs={12} sx={{
      // position: "absolute",
      // bottom: 0,
      width: "100%",
      height: "2.5rem",
      backgroundColor: '#e0e0ff',
    }}>Footer</Grid>
  );
}

export default Footer;