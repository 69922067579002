import {useDispatch, useSelector} from 'react-redux';
import { UserMenu } from './UserMenu';
import { IconButton, Link, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { logout } from '../slices/loginSlice';
import { useNavigate } from 'react-router-dom';

const Header = () => {

  const user = useSelector((state) => state.login.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = !!user;

  const clickHandler = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <div style={{
      display: "flex",
      padding: "8px",
      width: "100%",
      borderBottom: "1px solid gray",
      height: "40px",
    }}>
      <div style={{
        flex: "1",
      }}>
        {/* <IconButton color='default' size='medium' sx={{
          // padding: "2px",
          backgroundColor: "gray",
        }}><ArrowBackIcon /></IconButton> */}
      </div>
      <div style={{
        display: "flex",
        flex: "1",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}>
        <Typography variant="h5">nosi</Typography>
      </div>
      <div style={{
        display: "flex",
        flex: "1",
        justifyContent: "flex-end",
        width: "100%",
        alignItems: "center",
      }}
      >
        {isLoggedIn ?
          <UserMenu user={user} />
        : null}
        {!user ?
          <Link href="/sign-up" underline="hover">Sign-Up</Link>
        : null}
        {!user ?
            <Link href="/login" underline="hover">Login</Link>
        : null}
        {/* {user ?
          <Link underline="hover" onClick={clickHandler}>Logout</Link>
          : undefined
        } */}
      </div>
    </div>
  );
}

export default Header;