import {createSlice} from '@reduxjs/toolkit';
import { MyListApi } from '../utils/restApi';
import { setNotification } from './notificationSlice';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    user: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  }
});

export const login = (username, password) => {
  return async (dispatch, getState) => {

    MyListApi.auth(username, password)
      .then(data => {
        window.localStorage.setItem('token', JSON.stringify(data.token));
        return MyListApi.authMe(data.token);
      })
      .then(data => {
        window.localStorage.setItem('user', JSON.stringify(data));
        dispatch(setUser(data));
      })
      .catch(error => {
        dispatch(setNotification({
          message: error.message,
          type: "error",
        }));
      });
  }
}

export const logout = () => {
  return async (dispatch, getState) => {
    window.localStorage.setItem('user', JSON.stringify(null));
    window.localStorage.setItem('token', JSON.stringify(null));
    dispatch(setUser(null));
  }
}

export const { setUser } = loginSlice.actions;
export default loginSlice.reducer;