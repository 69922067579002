import { Typography } from "@mui/material";

export const CountText = ({ num, variant = "caption" }) => {
    const numText = num < 1_000 ? num :
        num < 1_000_000 ? `${(num / 1_000).toFixed(1)}k+` :
        num < 1_000_000_000 ? `${(num / 1_000_000).toFixed(1)}m+` :
        `${(num / 1_000_000_000).toFixed(1)}b+`;
    return (
        <Typography variant={variant}>{numText}</Typography>
    );
}