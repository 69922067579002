import { Typography } from "@mui/material";
import { differenceInMinutes, format } from "date-fns";
import { useEffect, useState } from "react";

const TIMEOUT = 60 * 1_000;

export const DateText = ({ date }) => {

  const updateText = date => {
    const d1 = date;
    const d2 = new Date();

    const mins = differenceInMinutes(d2, d1);
    const hrs = Math.round(mins / 60);
    const days = Math.round((mins / 60) / 24);

    return mins < 60 ? `${mins} min${mins > 1 ? 's' : ''} ago` :
      hrs < 24 ? `${hrs} hour${hrs > 1 ? 's' : ''} ago` :
      days < 7 ? `${days} day${days > 1 ? 's' : ''} ago` :
      format(date, 'MMM Lo, yyyy h:mmaaa');
  }

  const [dateText, setDateText] = useState(updateText(date));

  useEffect(() => {
    const timer = setInterval(() => {
      setDateText(updateText(date));
    }, TIMEOUT);
    return () => clearInterval(timer);
  }, []);

  return (
    <Typography variant="caption">{`Added ${dateText}`}</Typography>
  );
}