import { FormLabel, Typography } from "@mui/material";

// const useStyles = makeStyles({
//     label: {
//       fontSize: "0.70em",
//       textTransform: "uppercase",
//     },
//     value: {
//       fontWeight: "bold",
//     }
//   });

export const LabelValue = ({ label, value }) => {
    // const classes = useStyles();
    return (
        <div style={{
            paddingBottom: "16px",
        }}>
            <FormLabel sx={{ fontSize: "0.65em", textTransform: "uppercase", fontWeight: "bold" }}>{label}</FormLabel>
            <Typography sx={{  }}>{value}</Typography>
        </div>
    );
}