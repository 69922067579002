import {configureStore} from '@reduxjs/toolkit';
import loginReducer from './slices/loginSlice';
import itemsReducer from './slices/itemsSlice';
import tagsReducer from './slices/tagsSlice';
import browseReducer from './slices/browseSlice';
import notificationReducer from './slices/notificationSlice';

export default configureStore({
  reducer: {
    login: loginReducer,
    items: itemsReducer,
    tags: tagsReducer,
    browse: browseReducer,
    notification: notificationReducer,
  }
});