import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { MyListApi } from "../utils/restApi";

export const AddActionDialog = forwardRef((props, ref) => {

    const [open, setOpen] = useState(false);
    const [itemId, setItemId] = useState();
    const [userId, setUserId] = useState();
    const [action, setAction] = useState('');

    useImperativeHandle(ref, () => {
        return {
            openAddAction: (userId, itemId) => {
                setUserId(userId);
                setItemId(itemId);
                setOpen(true);
            }
        }
    });

    const handleSave = () => {
        MyListApi.collectItem(itemId, action)
            .then(data => {
                setOpen(false);
            })
    }

    return (
        <Dialog open={open}>
            <DialogTitle>Action</DialogTitle>
            <DialogContent>
                <TextField id="action_text" label="Action" variant="filled" onChange={e => setAction(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
});