import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { MyListApi } from "../utils/restApi";

export const AddCommentDialog = forwardRef((props, ref) => {

    const user = useSelector(state => state.login.user);
    const [open, setOpen] = useState(false);
    const [itemId, setItemId] = useState(null);
    const [comment, setComment] = useState('');

    useImperativeHandle(ref, () => {
        return {
            openAddComment: (itemId) => {
                setItemId(itemId);
                setOpen(true);
            }
        }
    });

    const handleSave = e => {
        const { comment_text } = e.target.elements;
        const comment = comment_text.value;
    };

    return (
        <Dialog open={open}>
            <form onSubmit={handleSave}>
            <DialogTitle>Add Comment</DialogTitle>
            <DialogContent>
                <TextField id="comment_text" label="Comment" variant="filled" maxRows={4} multiline={true} fullWidth />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button type="submit">Save</Button>
            </DialogActions>
            </form>
        </Dialog>
    );
});