
export const snakeToCamel = str => {
    return str.replace(
      /(?!^)_(.)/g,
      (_, char) => char.toUpperCase()
    );
  }

export const snakeToTitleCase = str => {
    return str
        .split("_")
        .filter(x => x.length > 0)
        .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
        .join(" ");
}