import { Button, Paper, TextField } from "@mui/material";
import { TagChooser } from "./TagChooser";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ItemList2 } from "./ItemList2";
import { useNavigate } from "react-router-dom";
import { MyListApi } from "../utils/restApi";
import { ItemFilterSelect } from "./ItemFilterSelect";
import { setNotification } from "../slices/notificationSlice";

const PER_PAGE = 10;

export const Browse = ({ userId }) => {

    const user = useSelector(state => state.login.user);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [tag, setTag] = useState(null);
    const [search, setSearch] = useState(null);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState(['createdAt', 'DESC']);
    const [browse, setBrowse] = useState({
        items: [],
        status: 'INITIAL',
        count: 0,
    });

    const getSort = sort => {
        switch (sort) {
            case 'new': return ['createdAt', 'DESC'];
            case 'popular': return ['createdAt', 'DESC'];
            case 'alpha': return ['name', 'ASC'];
            default: return null;
        }
    }

    useEffect(() => {
        MyListApi.getItems(userId, tag?.name, search, getSort(sort), page, PER_PAGE)
            .then(data => {
                setBrowse({
                    items: data.items,
                    status: 'LOADED',
                    count: data.count,
                });
            })
            .catch(error => {
                dispatch(setNotification({
                    message: error.message,
                    type: 'error',
                    open: true,
                }));
            });
    }, [tag, page, search, sort]);

    const handleSelect = tag => {
        setPage(0);
        setTag(tag);
    }

    const handleSearch = e => {
        setSearch(e.target.value);
    }

    const handleSort = sortBy => {
        setSort(sortBy);
    }

    const handleNext = e => {
        setPage(page + 1);
    }

    const handlePrev = e => {
        setPage(page - 1);
    }

    return (
        <Paper>
            <div style={{
                padding: "16px",
            }}>
                <div style={{
                    paddingBottom: "16px",
                    display: "flex",
                    gap: "8px",
                    borderBottom: "1px solid lightgray",
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "8px",
                        flex: "1",
                    }}>
                        <TagChooser onSelect={handleSelect} />
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        flex: "3",
                        gap: "8px",
                    }}>
                        <ItemFilterSelect selectSort={handleSort} />
                        <TextField variant="filled" size="small" placeholder="search" onChange={handleSearch} />
                        {user ?
                            <Button variant="contained" onClick={() => navigate("/create-item")}>Add Item</Button>
                        : null}
                    </div>
                </div>
                <div>
                    <ItemList2 data={browse} tag={tag} />
                    <Button onClick={handlePrev} disabled={page <= 0} size="small">Prev</Button>
                    <Button onClick={handleNext} disabled={(page + 1) * PER_PAGE >= browse.count} size="small">Next</Button>
                </div>
            </div>
        </Paper>
    );
}