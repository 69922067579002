import {createSlice} from '@reduxjs/toolkit';
import { MyListApi } from '../utils/restApi';

export const itemsSlice = createSlice({
  name: 'items',
  initialState: {
    items: [],
    status: 'INITIAL',
    user: {
      items: [],
      status: 'INITIAL',
      page: 0,
      perPage: 10,
      total: null,
      pageable: {
        cur: 0,
        per: 10,
        total: null,
      }
    },
    search: {
      items: [],
      status: 'INITIAL',
    },
  },
  reducers: {

    setItems: (state, action) => {
      state.items = action.payload;
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },

    setUserItems: (state, action) => {
      state.user.items = action.payload;
    },

    setUserStatus: (state, action) => {
      state.user.status = action.payload;
    },

    setUserPage: (state, action) => {
      state.user.page = action.payload.page;
      state.user.perPage = action.payload.perPage;
      state.user.total = action.payload.total;
    },

    setSearchItems: (state, action) => {
      state.search.items = action.payload;
    },

    setSearchStatus: (state, action) => {
      state.search.status = action.payload;
    },
  }
});

export const getItems = userId => {
  return async (dispatch, getState) => {

    const { items } = getState();

    if (!['INITIAL', 'LOADED'].includes(items.status)) {
      return;
    }

    dispatch(setStatus('FETCHING'));

    MyListApi.getItems(userId).then(data => {
      dispatch(setItems(data.items));
      dispatch(setStatus('LOADED'));
    }).catch(error => {
      dispatch(setStatus('ERROR'));
    });
  }
}

export const getUserItems = (userId, page = 0, perPage = 10) => {
  return async (dispatch, getState) => {
    const { items } = getState();
    if (!['INITIAL', 'LOADED'].includes(items.user.status)) {
      return;
    }

    dispatch(setUserStatus('LOADING'));

    MyListApi.getItems(userId, null, null, null, page, perPage).then(data => {
      dispatch(setUserItems(data.items));
      dispatch(setUserStatus('LOADED'));
    }).catch(() => {
      dispatch(setUserStatus('ERROR'));
    });
  }
}

export const searchItems = (userId, tag, name, page = 0, perPage = 10) => {
  return async (dispatch, getState) => {
    const { items } = getState();
    if (!['INITIAL', 'LOADED'].includes(items.search.status)) {
      return;
    }

    dispatch(setSearchStatus('LOADING'));

    MyListApi.getItems(userId, tag, name, null, page, perPage).then(data => {
      dispatch(setSearchItems(data.items));
      dispatch(setSearchStatus('LOADED'));
    }).catch(() => {
      dispatch(setSearchStatus('ERROR'));
    });
  }
}

export const collectItem = (itemId, action) => {
  return async (dispatch, getState) => {

    MyListApi.collectItem(itemId, action)
      .then(data => {
        dispatch(setUserStatus('INITIAL'));
      })
      .catch(() => {

      });

  }
}

export const createItem = (userId, tag, name, data) => {
  return async (dispatch, getState) => {
    MyListApi.createItem(userId, tag, name, data)
      .then(data => {
        dispatch(setUserStatus('INITIAL'));
      });
  }
}

export const { setItems, setStatus, setUserItems, setUserStatus, setSearchItems, setSearchStatus } = itemsSlice.actions;
export default itemsSlice.reducer;