import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeNotification } from "../slices/notificationSlice";

export const Notification = props => {

    const dispatch = useDispatch();
    // const [open, setOpen] = useState(true);
    const open = useSelector(state => state.notification.open);
    const message = useSelector(state => state.notification.message);
    const type = useSelector(state => state.notification.type);

    const handleClose = (e, reason) => {
        if (reason == 'clickaway') {
            return;
        }
        // setOpen(false);
        dispatch(closeNotification());
    }

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
            >
                <Alert severity={type}>{message}</Alert>
            </Snackbar>
        </>
    );
}