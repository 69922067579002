import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip, Zoom } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HomeIcon from '@mui/icons-material/Home';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSelector } from 'react-redux';

export const SideMenu = props => {

    const user = useSelector(state => state.login.user);
    const navigate = useNavigate();

    return (
        <div style={{
            padding: "4px",
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Tooltip title="Home" placement='left'>
                <IconButton size='large' color='primary' onClick={() => navigate("/")}>
                    <HomeIcon fontSize='large' />
                </IconButton>
            </Tooltip>
            {user ?
            <Tooltip title="Create Item" placement='left'>
                <IconButton size='large' color='primary' onClick={() => navigate('/create-item')}>
                    <AddCircleIcon fontSize='large' />
                </IconButton>
            </Tooltip>
            : undefined}
            {/* <IconButton size='large' color='primary'>
                <AccountBoxIcon fontSize='large' />
            </IconButton> */}


        </div>
    );
}