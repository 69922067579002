import { Button, Chip, IconButton, Link, Paper, Typography } from "@mui/material";
import { differenceInMinutes, format, parseISO } from "date-fns";
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useRef } from "react";
import { AddActionDialog } from "./AddActionDialog";
import { useSelector } from "react-redux";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import { CountText } from "./CountText";
import { DateText } from "./DateText";

export const ItemList2 = ({ data, tag }) => {

    const ref = useRef();
    const user = useSelector(state => state.login.user);

    const handleAddAction = (userId, itemId) => {
        ref.current.openAddAction(userId, itemId);
    }

    return (
        <div>
            <AddActionDialog ref={ref} />
            {data.items.map(i => {
                return (
                    <div key={i.id} style={{
                        padding: "16px",
                        borderBottom: "1px solid lightgray",
                    }}>
                        <div style={{
                            // paddingBottom: "0px",
                        }}>
                            <Link underline="hover" href={`/items/${i.id}`}>{i.title}</Link>
                            <div><DateText date={parseISO(i.created_at)}/>&nbsp;<Link href={`/user/${i.created_by.name}`} variant="caption">{`@${i.created_by.name}`}</Link><Typography variant="caption">{`${!tag ? ` >${i.tag.name}` : ``}`}</Typography></div>
                        </div>
                        <div style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                        }}>
                            {user ?
                                <IconButton size="small" onClick={() => handleAddAction(user.id, i.id)}><AddCircleOutlineIcon fontSize="small" /></IconButton>
                            : null}
                            {i.action_stats ? i.action_stats.sort((a, b) => a.action.localeCompare(b.action)).map(a => {
                                const key = `action-stat-id-${a.action}`;
                                const label = <><span>{a.action}</span>&nbsp;<CountText num={a.count} /></>
                                const color = i.actions && i.actions.includes(a.action) ? 'primary' : 'default';
                                return <Chip key={key} label={label} size="small" color={color} />
                            }) : null}
                            <Chip icon={<ThumbUpIcon fontSize="small" color="info" />} label={<CountText num={i.like_count} />} size="small" />
                            <Chip icon={<CommentIcon fontSize="small" color="info" />} label={<CountText num={i.comment_count} />} size="small" />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}