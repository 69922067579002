import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTags } from "../slices/tagsSlice";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

export const TagSelect = ({ onChange, tagId = null, id}) => {

    const dispatch = useDispatch();
    const tags = useSelector(state => state.tags);

    let selectedTag = null;

    if (tagId && tags.data && tags.data.length > 0) {
        selectedTag = tags.data.find(t => t.id === tagId);
    }

    useEffect(() => {
        dispatch(getTags());
        if (onChange && tags.data && tags.data.length) {
            onChange(null, tags.data.find(t => t.id === tagId));
        }
    }, [tagId, selectedTag?.id]);

    const handleChange = (event, value, reason) => {
        if (onChange) {
            onChange(event, value, reason);
        }
    }

    if (tags.status !== 'LOADED') {
        return <CircularProgress />
    }

    return (
        <>
            <Autocomplete
                id={id}
                options={tags.data}
                renderInput={params => {
                    return <TextField {...params} />
                }}
                getOptionLabel={option => option.name}
                fullWidth
                defaultValue={selectedTag}
                loading={tags.status !== 'LOADED'}
                onChange={handleChange}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disabled={!!tagId}
            />
        </>
    );
}