import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";

export const ConfirmDialog = forwardRef(({ title, message }, ref) => {

    const [open, setOpen] = useState(false);
    const [onConfirm, setOnConfirm] = useState();

    useImperativeHandle(ref, () => {
        return {
            openConfirm: (onConfirm) => {
                setOnConfirm(() => onConfirm);
                setOpen(true);
            }
        }
    });

    const handleCancel = () => {
        // if (onCancel) onCancel();
        setOpen(false);
    }

    const handleConfirm = () => {
        if (onConfirm) onConfirm();
        setOpen(false);
    }

    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCancel()}>Cancel</Button>
                <Button onClick={() => handleConfirm()}>Save</Button>
            </DialogActions>
        </Dialog>
    );
});