import { Autocomplete, Button, Card, CardActions, CardContent, Grid, TextField, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import { ItemPropertyField } from "./ItemPropertyField";
import { useState } from "react";
import { TagSelect } from "./TagSelect";
import { useDispatch, useSelector } from "react-redux";
import { MyListApi } from "../utils/restApi";
import { setUserStatus } from "../slices/itemsSlice";

export const ItemCreate = props => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.login.user);
    const [searchParams] = useSearchParams();
    const tagId = searchParams.get('tagId');
    const name = searchParams.get('name');
    const [properties, setProperties] = useState([]);
    const [selectedTag, setSelectedTag] = useState();

    const handleAddProperty = () => {
        setProperties([...properties, {}]);
    }

    const handleRemoveProperty = () => {
        setProperties(properties.slice(0, -1));
    }

    const handleSubmit = e => {
        e.preventDefault();

        const { name } = e.target.elements;
        const data = {};
        for (let ix = 0; ix < properties.length; ix++) {
            const name = e.target.elements[`prop-name-${ix}`].value;
            const value = e.target.elements[`prop-value-${ix}`].value;
            data[name] = value;
        }

        MyListApi.createItem(selectedTag.id, name.value, data)
            .then(() => {
                dispatch(setUserStatus('INITIAL'))
            })
            .catch(() => {
            });
    }

    const handleTagSelect = (_, val) => {
        setSelectedTag(val);
    }

    return (
        <form onSubmit={handleSubmit}>
            <Card sx={{ padding: "20px", }}>
                <CardContent>
                    <Grid container item xs={12} direction="row" spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h4">Create New Item</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TagSelect tagId={tagId} onChange={handleTagSelect} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="name" label="Name" source="name" defaultValue={name} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Item Properties</Typography>
                    </Grid>
                    {properties.map((p, index) => (
                        <Grid item xs={12} key={index}>
                            <ItemPropertyField property={p} index={index} onRemove={handleRemoveProperty} />
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <ItemPropertyField isAdd={true} onAdd={handleAddProperty} />
                    </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>Cancel</Button>
                    <Button startIcon={<SaveIcon />} variant="contained" type="submit">Save</Button>
                </CardActions>
            </Card>
        </form>
    );
}