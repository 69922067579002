import { Badge, Button, Card, CardActions, CardContent, CardHeader, Chip, CircularProgress, Divider, FormLabel, IconButton, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MyListApi } from "../utils/restApi";
import { snakeToCamel, snakeToTitleCase } from "../utils/utils";
import { TagIcon } from "./TagIcon";
import AddCommentIcon from '@mui/icons-material/AddComment';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { Comment } from "./Comment";
import { ItemComments } from "./ItemComments";
import { AddCommentDialog } from "./AddCommentDialog";
import CategoryIcon from '@mui/icons-material/Category';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useSelector } from "react-redux";
import { LabelValue } from "./LabelValue";

export const ItemDetails = props => {

  const ref = useRef();
  const user = useSelector(state => state.login.user);
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState(
    {
      properties: {},
      data: null,
      status: 'INITIAL',
    }
  );
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    if (item.status === 'INITIAL') {
      setItem({
        properties: {},
        data: null,
        status: 'LOADING',
      });
      MyListApi.getItem(itemId)
        .then(data => {
          setItem({
            data: data,
            properties: JSON.parse(data.data),
            status: 'LOADED',
          });
        })
        .catch(error => {
          setItem({
            properties: {},
            data: null,
            status: 'ERROR',
          });
        });

        // MyListApi.isItemLiked(user.id, itemId)
        //   .then(data => {
        //     setIsLiked(data.data);
        //   });
    }

  }, [item.status]);

  const isItemLoaded = item.status === 'LOADED';

  const handleLike = () => {
    MyListApi.likeItem(user.id, item.data.id)
      .then(data => {
        setIsLiked(true);
      });
  }

  return (
    <Paper sx={{
      // padding: "16px",
      width: "100%",
    }}>
      {!isItemLoaded ? (
        <CircularProgress />
      ) : (
        <div>
          <div style={{
            fontSize: "0.6em",
            textTransform: "uppercase",
            // paddingBottom: "16px",
            padding: "8px",
          }}>{`> ${item.data.tag.name}`}</div>
          <div style={{
            // paddingBottom: "16px",
            padding: "16px",
            fontSize: "1.5em",
          }}>{item.data.name}</div>
          <div style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "16px",
          }}>
            {Object.entries(item.properties).map(p => {
              const key = snakeToTitleCase(p[0]);
              const val = p[1];
              return <LabelValue key={key} label={key} value={val} />
            })}
          </div>
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 16px 8px 16px",
            backgroundColor: "#DDE6ED",
          }}>
            <IconButton size="small"><CategoryIcon /></IconButton>
            <IconButton size="small" onClick={handleLike} color={isLiked ? "primary" : "default"}><ThumbUpIcon /></IconButton>
            <IconButton size="small"><InsertCommentIcon /></IconButton>
            <IconButton size="small" onClick={() => ref.current.openAddComment(itemId)}><AddCommentIcon /></IconButton>
            <AddCommentDialog ref={ref} />
          </div>
          <ItemComments itemId={itemId} />
        <div style={{
          padding: "4px",
          display: "flex",
          justifyContent: "flex-end",
          padding: "16px",
        }}>
          <Button onClick={() => navigate(-1)} color="secondary" variant="contained">Back</Button>
        </div>
      </div>
      )}
    </Paper>
  );
}