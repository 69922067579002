import {
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Collection } from './Collection';
import { Sandbox } from './Sandbox';
import { Browse } from './Browse';
import { Popular } from './Popular';

const TabPanel = ({ value, tabLabel, children }) => {
  return (
    <div
      hidden={value !== tabLabel}
    >
      {children}
    </div>
  );
};

const getSavedTab = () => {
  const t = window.localStorage.getItem('tab-label');
  return t ? t : 'home';
}

export const Dashboard = () => {

  const user = useSelector(state => state.login.user);
  const navigate = useNavigate();
  const [tabLabel, setTabLabel] = useState('home');

  useEffect(() => {
    let t = getSavedTab();
    if ((t == 'my_stuff' || t == 'my_wall') && !user) {
      t = 'home';
    }
    setTabLabel(t);
  });

  const onTabSelect = (event, newValue) => {
    setTabLabel(newValue);
    window.localStorage.setItem('tab-label', newValue);
  }

  let index = 0;

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}
        sx={{
          marginBottom: '1em',
        }}
      >
        <Tabs value={tabLabel} onChange={onTabSelect}>
          {/* <Tab label="My Stuff" value="my_stuff" /> */}
          <Tab label="Latest" value="home" />
          <Tab label="My Wall" value="my_wall" />
          <Tab label="Search" value="search" />
          {/* <Tab label="Sandbox" value="sandbox" /> */}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        {/* <TabPanel value={tabLabel} tabLabel="my_stuff">
          <Browse userId={user?.id} />
        </TabPanel> */}
        <TabPanel value={tabLabel} tabLabel="my_wall">
          {/* <Browse userId={user?.id} /> */}
        </TabPanel>
        <TabPanel value={tabLabel} tabLabel="home">
          {/* <Browse /> */}
        </TabPanel>
        <TabPanel value={tabLabel} tabLabel="search">
          <Browse />
        </TabPanel>
        {/* <TabPanel value={tabLabel} tabLabel="sandbox">
          <Sandbox />
        </TabPanel> */}
      </Grid>
    </Grid>
  );
};