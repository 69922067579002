import { createSlice } from "@reduxjs/toolkit";
import { MyListApi } from "../utils/restApi";

export const browseSlice = createSlice({
    name: 'browse',
    initialState: {
        items: [],
        status: 'INITIAL',
        page: 0,
        count: 0,
    },
    reducers: {
        setBrowseItems: (state, action) => {
            state.items = action.payload;
        },
        setBrowseStatus: (state, action) => {
            state.status = action.payload;
        },
        setBrowseCount: (state, action) => {
            state.count = action.payload;
        },
    },
});

export const getBrowseItems = (userId, tag, name, page, perPage = 10) => {
    return async (dispatch, getState) => {

        const { browse } = getState();

        if (!['INITIAL', 'LOADED'].includes(browse.status)) {
            return;
        }

        dispatch(setBrowseStatus('LOADING'));

        MyListApi.getItems(userId, tag, name, null, page, perPage)
            .then(data => {
                dispatch(setBrowseItems(data.items));
                dispatch(setBrowseStatus('LOADED'));
                dispatch(setBrowseCount(data.count));
            })
            .catch(error => {
                dispatch(setBrowseStatus('ERROR'));
            });
    }
}

export const { setBrowseItems, setBrowseStatus, setBrowseCount } = browseSlice.actions;
export default browseSlice.reducer;