import { Button, Card, Grid, Paper, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MyListApi } from "../utils/restApi";
import { login } from "../slices/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setNotification } from "../slices/notificationSlice";

export const SignUp = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.login.user);

    const handleSubmit = e => {
        e.preventDefault();
        const { name, email, display_name, password } = e.target.elements;
        MyListApi.signUp(name.value, display_name.value, email.value, password.value)
            .then(data => {
                dispatch(login(email.value, password.value));
                navigate('/');
            })
            .catch(error => {
                dispatch(setNotification({
                    message: error.message,
                    type: 'error',
                    open: true,
                }));
            });
    }

    return (

        <Paper sx={{
            width: "100%",
        }}>
            <form onSubmit={handleSubmit}>
                <div style={{
                    padding: "16px 16px 16px 16px",
                }}>
                    Sign-Up
                </div>
                <div style={{
                    padding: "16px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                }}>
                    <TextField label="Username" id="name" variant="filled" fullWidth />
                    <TextField label="Display Name" id="display_name" variant="filled" fullWidth />
                    <TextField label="Email" id="email" variant="filled" fullWidth />
                    <TextField label="Password" id="password" variant="filled" type="password" fullWidth />
                </div>
                <div style={{
                    padding: "16px",
                    display: "flex",
                    gap: "16px",
                    justifyContent: "flex-end",
                }}>
                    <Button variant="contained" onClick={() => navigate("/login")}>Cancel</Button>
                    <Button variant="contained" type="submit">Save</Button>
                </div>
            </form>
        </Paper>
    );
}