import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useState } from "react";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const FILTERS = [
    ['new', 'New'],
    ['popular', 'Popular'],
    ['alpha', 'Alphabetical'],
];

export const ItemFilterSelect = ({ selectSort }) => {

    const [filter, setFilter] = useState('new');

    const handleChange = val => {
        setFilter(val.target.value);
        if (selectSort) selectSort(val.target.value);
    }

    return (
        <FormControl size="small">
            <InputLabel id="item-filter-label">Filter</InputLabel>
            <Select
                labelId="item-filter-label"
                value={filter}
                label="Filter"
                onChange={handleChange}
                variant="filled"
            >
                {FILTERS.map(f => {
                    const [key, label] = f;
                    return <MenuItem key={key} value={key}>{label}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}