import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        message: "",
        type: "success",
        open: false,
    },
    reducers: {
        setNotification: (state, action) => {
            state.message = action.payload.message;
            state.type = action.payload.type;
            state.open = true;
        },
        closeNotification: (state, action) => {
            state.open = false;
        },
    },
});

export const { setNotification, closeNotification } = notificationSlice.actions;
export default notificationSlice.reducer;